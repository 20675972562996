import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import Color from 'color'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#EDF2F1',
      neutralLight2: '#D3E3E1',
      neutralLight1: '#BACBC9',
      neutralDark5: '#56606C',
      neutralDark4: '#3D6F69',
      neutralDark3: '#265550',
      neutralDark2: '#173834',
      neutralDark1: '#000000',
      primaryDark1: '#568D86',
      dangerLight1: '#9A3E53',
    },
    getHoverColor: (color: string, strength = 0.1): string => {
      const c = Color(color)
      return c.darken(strength).hex()
    },
  },
  fontFamily: {
    heading: "'Prata', serif",
    paragraph: "'Zen Kaku Gothic Antique', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
